import React from 'react'

import { gql, useApolloClient } from '@apollo/client'
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank'

import BPMTable from '../../../components/BPMTable/BPMTable'
import DeleteIcon from '@material-ui/icons/Delete';
import {ExportFooterItemCreator} from "../../../components/BPMTable/TableFooterItems"
import InvoiceGroupTableCreator from '../../../tableDefinitions/Invoice/InvoicePayment/InvoiceGroupTableCreator'
import tableCreatorCreatorFactoryCreator from '../../../components/BPMTable/TableCreatorFactory/TableCreatorCreatorFactoryCreator'

const DELETE_APGL = gql `
    mutation($ids : [String]) {
        deleteApgl(ids: $ids) {
            succeeded
        }
    }
`

class InvoicePaymentGroupDisplayStrategy {
    doFor = (enhanceTable) => {
        enhanceTable.hideEditButton()
        enhanceTable.hideNewButton();
    }
}


function InvoicePaymentGroupSelectComponent(props){
    const  client  = useApolloClient()
    const { 
        closePopper,
        clearAllSelections,
        ListItemIcon,
        ListItemText,
        MenuItem,
        t,
        selectedRows,
        handleErrorMessage,
        handleMessage,
        refreshTable,
        allRowsSelected,
        filters
    } = props

    const ids = []
    for(let data of selectedRows){
        ids.push(Number(data.id))
    }

    const handleDelete = (selected_ids) =>{
        clearAllSelections();
        closePopper();
        client.mutate({
          mutation: DELETE_APGL,
          variables : {ids : selected_ids, allRecords: allRowsSelected, filters: JSON.stringify(filters)}
        }).then(
          res => {
              handleMessage("Done")
              refreshTable()
              closePopper()

        }).catch(
          err =>{
              handleErrorMessage(String(err))
        })
    }
    return (
        <>
            <MenuItem onClick={() => {handleDelete(selectedRows.map(data=>data.id))}}>
              <ListItemIcon>
                  <DeleteIcon />
              </ListItemIcon>
              <ListItemText primary={t("tableFooter:Delete Selected APGLS")} />
            </MenuItem>
            <MenuItem onClick={() => {closePopper(); clearAllSelections()}}>
                <ListItemIcon>
                    <CheckBoxOutlineBlankIcon />
                </ListItemIcon>
                <ListItemText primary={t("tableFooter:Clear Selected Checkboxes")} />
            </MenuItem>
        </>
    )
}


class SelectedRowActionsStrategy {
    doFor({
        closePopper,
        clearAllSelections,
        ListItemIcon,
        ListItemText,
        MenuItem,
        t,
        selectedRows,
        handleErrorMessage,
        handleMessage,
        refreshTable,
        allRowsSelected
    }) {
        return (
            <InvoicePaymentGroupSelectComponent 
                closePopper={closePopper}
                clearAllSelections={clearAllSelections}
                ListItemIcon={ListItemIcon}
                ListItemText={ListItemText}
                MenuItem={MenuItem}
                t={t}
                selectedRows={selectedRows}
                handleErrorMessage={handleErrorMessage}
                handleMessage={handleMessage}
                refreshTable={refreshTable}
            />
        )
    }
}

const InvoicePaymentGroup = (props) => {
    document.title = `APGL - BubbleBPM`
    const client = useApolloClient()

    var tableCreatorCreator = tableCreatorCreatorFactoryCreator.getTableCreatorCreatorFromIdentifier(InvoiceGroupTableCreator.getId(), null)
    tableCreatorCreator.setParentProps(props)
    const tableCreator = tableCreatorCreator.getTableCreator('APGL')
    tableCreator.setDisplayStrategy(new InvoicePaymentGroupDisplayStrategy())
    tableCreator.setTableFooterItems([new ExportFooterItemCreator()])
    tableCreator.setSelectedRowActionsStrategy(new SelectedRowActionsStrategy())

    const path = props.match.url
    
    return (
        <BPMTable
            TableCreator={tableCreator}
            path={path}
        />
    )
}

export default InvoicePaymentGroup