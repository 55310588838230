
import React, { useState } from 'react'
import { gql } from "@apollo/client"
import { useCurrentUser } from '../../redux/selectors'
import OptimizationTable from './OptimizationTable'
import PeriodGraph from './PeriodGraph'
import ReportsFilter from '../../components/ReportsFilter/ReportsFilter'
import { useLastPublishedPeriod } from '../../redux/selectors'
import Loading from '../../components/Loading/Loading'
import { useTranslation } from 'react-i18next';

const OPTIMIZATION_QUERIES = gql`
  query($period:Period, $currency:String, $reportType:String){
    optimizationPeriodTotals(period:$period, currency:$currency, reportType:$reportType){
      lineCount
      cost
      date
    }
  }
`

const reports = [
  'zero_usage',
  'not_assigned',
  'assigned_to_terminated',
  'assigned_to_user_with_multiple',
];

function Optimization({}) {
    const { t } = useTranslation();

    const currentUser = useCurrentUser();
    const LastPublishedPeriod = useLastPublishedPeriod()

    const [currenctReport, setCurrenctReport] = useState(reports[0]);
    const [currency, setCurrency] = useState(
      {
        code:currentUser?.currency.code, 
        symbol:currentUser?.currency.symbol
      }
    )

    const [period, setPeriod] = useState({year: LastPublishedPeriod.year, month: LastPublishedPeriod.month})

    const handleChangeCurrency = (currencyCode, symbol) => {
      setCurrency({code:currencyCode, symbol:symbol})
    }

    const handleChangeDate = (year, month) => {
      setPeriod({year:year, month:month})
    }

    const generateFilters = () =>{
      const periodString = `${period.year}-${period.month}`
      return {period: periodString, currency:currency.code, reportType: currenctReport}
    }

    const infoButtonData = {
      label: t("Lines with no usage and cost above cero")
    }

    const buttonsData = [
      {
          label: t("Zero Usage"),
          color: currenctReport != 'zero_usage' ? "gray" : "#55758C",
          variant: currenctReport != 'zero_usage' ? "outlined" : "contained",
          onClick: () => setCurrenctReport('zero_usage'),
      },
      {
        label: t("Not assigned"),
        color: currenctReport != 'not_assigned' ? "gray" : "#55758C",
        variant: currenctReport != 'not_assigned' ? "outlined" : "contained",
        onClick: () => setCurrenctReport('not_assigned'),
      },
      {
        label: t("Assigned to invalid"),
        color: currenctReport != 'assigned_to_terminated' ? "gray" : "#55758C",
        variant: currenctReport != 'assigned_to_terminated' ? "outlined" : "contained",
        onClick: () => setCurrenctReport('assigned_to_terminated'),
      },
      {
        label: t("Multiple assigments"),
        color: currenctReport != 'assigned_to_user_with_multiple' ? "gray" : "#55758C",
        variant: currenctReport != 'assigned_to_user_with_multiple' ? "outlined" : "contained",
        onClick: () => setCurrenctReport('assigned_to_user_with_multiple'),
      },
  ]

    return (
      <>
        <ReportsFilter
          buttons={buttonsData}
          info={infoButtonData}
          date={period}
          onChangeDate={handleChangeDate}
          currencySelected={currency.code}
          onChangeCurrency={handleChangeCurrency}
          checkDisabledMonths={true}
        />
        <Loading query={OPTIMIZATION_QUERIES} variables={generateFilters()}>
          {(data)=>{
            return(
                <div>
                  <PeriodGraph currency={currency} data={data} t={t} className="mt-2 mb-2" />
                  <OptimizationTable title={"Optimization"} currency={currency} data={data} filters={generateFilters()}/>
                </div>
            )
          }}
        </Loading>
      </>
    )
}

export default Optimization;
