import React, { useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { gql, useMutation, useApolloClient } from '@apollo/client'
import ClearIcon from '@material-ui/icons/Clear'
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank'
import { useCurrentUser } from '../../../redux/selectors'
import PopUpComponentCreator from "../../../components/BPMTable/TableComponents/CellComponents/PopupCellComponent"
import InvoiceClosePaymentePopUp from './InvoiceClosePaymentePopUp'
import BPMTable from '../../../components/BPMTable/BPMTable'
import Loading from "../../../components/Loading/Loading"
import DoneAllIcon from '@material-ui/icons/DoneAll';
import { Button, Dialog, DialogTitle, DialogActions, DialogContent, DialogContentText, Divider, Paper, Typography, Input, FormControl, InputLabel, Popper, ClickAwayListener } from '@material-ui/core'
import { useDispatch } from 'react-redux'
import { AddBPMApplicationMessages } from '../../../redux/reducers/BPMReducer'
import {CloseAPGLPaymentFooterCreator} from "../../../components/BPMTable/TableFooterItems"
import tableCreatorCreatorFactoryCreator from '../../../components/BPMTable/TableCreatorFactory/TableCreatorCreatorFactoryCreator'
import InvoicePaymentsTableCreator from '../../../tableDefinitions/Invoice/InvoicePayment/InvoicePaymentTableCreator'
import massiveLoadTemplateMutation from '../../../components/BPMTable/TableComponents/Dialogs/MassiveLoadTemplateMutation'


class InvoicePaymentDisplayStrategy {
    doFor = (enhanceTable) => {
        enhanceTable.hideEditButton()
        enhanceTable.hideNewButton();
    }
}

const PROCESS_PAYMENTS = gql `
  mutation($ids : [String], $apglId:String, $allRecords: Boolean, $filters: String) {
      processPayments(ids: $ids, apglId:$apglId, allRecords: $allRecords, filters: $filters) {
          invoicePaymentGroup {
              id
          }
      }
  }
`

const APGLS =  gql `query {
  availableCompanyApgls {
    edges {
      node {
        id
        name
      }
    }
  }
}`

function InvoicePaymentSelectComponent(props){
    const client = useApolloClient();
    const [isOpenApgls, setIsOpenApgls] = useState(false)
    const [anchorEl, setAnchorEl] = useState(null)
    const [selected, setSelected] = useState({id:null, label:"-"})

    const { closePopper,
      clearAllSelections,
      ListItemIcon,
      ListItemText,
      MenuItem,
      t,
      selectedRows,
      handleErrorMessage,
      handleMessage,
      refreshTable,
      allRowsSelected,
      filters
      } = props

    var available = true;
  
    let ids = []
    for(let data of selectedRows){
        available = available && data.state === "AVAILABLE";
        ids.push(data.id)
    }
    
    const handleAction = (selected_ids) =>{
      setIsOpenApgls(false)
      clearAllSelections();
      closePopper();
      client.mutate({
        mutation: PROCESS_PAYMENTS,
        variables : {
          ids: selected_ids,
          apglId: selected.id,
          allRecords: allRowsSelected,
          filters: JSON.stringify(filters)
        }
      }).then(
        res => {
            handleMessage("Done")
            refreshTable()
        }
      ).catch(
        err =>{
          handleErrorMessage(String(err))
        }
      )
    }

    const handleOpenApgls = () =>{
      setIsOpenApgls(true)
    }

    const handleCloseAPGLS = () => {
      setIsOpenApgls(false)
      closePopper()
      clearAllSelections()
    }

    const handleSelect = (id, name) => {
      setSelected({id:id, label:name})
      setAnchorEl(null)
    }
    return (
        <>
          {(allRowsSelected || available > 0) ?
            <MenuItem onClick={handleOpenApgls}>
              <ListItemIcon>
                  <ClearIcon />
              </ListItemIcon>
              <ListItemText primary={allRowsSelected ? t("tableFooter:Process available invoices for payment") : t("tableFooter:Process invoice for payment")} />
            </MenuItem>
           : null   }
          <MenuItem onClick={() => {closePopper(); clearAllSelections()}}>
              <ListItemIcon>
                  <CheckBoxOutlineBlankIcon />
              </ListItemIcon>
              <ListItemText primary={t("tableFooter:Clear Selected Checkboxes")} />
          </MenuItem>
          <Dialog id="apgl-form" open={isOpenApgls} onClose={handleCloseAPGLS}>
            <Loading query={APGLS}>
              {(data)=>
            <Paper style={{width:400, height:250, textAlign:"center"}}>
              <Typography variant="h6" style={{margin:"3%"}}>APGLS</Typography>
              <Divider style={{margin:"3%"}} />
              <FormControl>
              <InputLabel>APGL</InputLabel>

                <Input 
                  style={{width:250, cursor:"pointer", textAlign:"center"}}
                  value={selected.label} 
                  onClick={(e)=>setAnchorEl(e.currentTarget)}/>
                  <Popper style={{zIndex:999999}} open={Boolean(anchorEl)} anchorEl={anchorEl}>
                    <ClickAwayListener onClickAway={()=>setAnchorEl(false)}>
                      <Paper style={{width:250}}>
                        <MenuItem onClick={()=>handleSelect(null, "-")} id="" key={''} value={null}>
                        -
                        </MenuItem>
                        {     
                        data.availableCompanyApgls.edges.map(apgl => 
                          <MenuItem id={apgl.node.id} key={apgl.node.id} value={apgl.node.id} onClick={()=>handleSelect(apgl.node.id, apgl.node.name)}>
                            {apgl.node.name}
                          </MenuItem>
                          )

                        }
                      </Paper>
                    </ClickAwayListener>
                  </Popper>

              </FormControl>


              <Button disabled={!selected.id} onClick={()=>handleAction(ids)} style={{backgroundColor:selected.id ? "#0a1f2e" : "gray", right:10,bottom:10, color:"white", position:"absolute"}} >{t("Process")}</Button>
            </Paper>
              }
            </Loading>
          </Dialog>
        </>
    )
}
  

class InvoicePaymentSelectedRowActionsStrategy {
    doFor({
        closePopper,
        clearAllSelections,
        ListItemIcon,
        ListItemText,
        MenuItem,
        t,
        selectedRows,
        handleErrorMessage,
        handleMessage,
        refreshTable,
        allRowsSelected,
        filters
    }) {
        return (
            <InvoicePaymentSelectComponent 
                closePopper={closePopper}
                clearAllSelections={clearAllSelections}
                ListItemIcon={ListItemIcon}
                ListItemText={ListItemText}
                MenuItem={MenuItem}
                t={t}
                selectedRows={selectedRows}
                handleErrorMessage={handleErrorMessage}
                handleMessage={handleMessage}
                refreshTable={refreshTable}
                allRowsSelected={allRowsSelected}
                filters={filters}
            />
        )
    }
}

const mustShowPopUp = (data) => {
  if(data === "A_2"){
    return false
  }
  else{
    return true
  }
}

function ClosePaymentButton(props){
    useEffect(() => {
      if (props.rowData.state !== "ASSIGNED") {
          props.disable();
      }
    }, [props.rowData]);

    return (<DoneAllIcon  style={{padding: 0}} color={props.rowData.state !== "ASSIGNED" ? "disabled" :"primary"}/>)
}

const InvoicePaymentCloseAPGLDialog = (props) => {
  const [ file, setFile ] = useState(null);
  const [ isLoading, setLoading ] = useState(false);
  const [ runMutation, {loading} ] = useMutation(massiveLoadTemplateMutation)

  const dispatch = useDispatch()

  const { t } = useTranslation('invoicePayments');
  const client = useApolloClient();

  const tableEndpoint = 'close_invoice_payments';

  const onClickDownloadTemplate = () => {
    setLoading(true)

    runMutation({
        variables: {
            model: tableEndpoint
        }
    })
    .then(result => {
        const success = result.data;
        if (success) {
            handleQueryExecuted(true, t("translations:Template generation job has been initiated, click here or go to Jobs->Data Load Templates, and download your Template when task finished"))
        } else {;
            handleQueryExecuted(false, t('translations:An error occurred trying to translations'))
        }
    })
    .catch(error => {
        handleQueryExecuted(false, JSON.stringify(error))
    })
  };

  const onProcess = () => {
    client.mutate({
      mutation : gql `
        mutation closePaymentsWithFile(
          $recordsFile:Upload,
        ) {
          closePaymentsWithFile(
            recordsFile:$recordsFile,
          ) {
            succeeded
          }
        }
      `,
      variables : {recordsFile:file}
    }).then(res => onClose()).catch(err => err => console.log(err))
  }

  const handleQueryExecuted  = (success, message) => {
    setLoading(false);
    onClose();

    dispatch(AddBPMApplicationMessages({
      text: message,
      variant: success ? 'success' : 'error',
      snakBarCursor: success ? {cursor: 'pointer'} : null,
      duration: 8000,
      clckRedirect: '/dataLoadTemplates'
    }))
  }

  const onClose = () => {
    setFile(null);
    props.onClose();
  }

  return (
    <>
      <Dialog
        open={props.open}
        onClose={onClose}
        aria-labelledby="alert-dialog-title"
      >
        <DialogTitle id="alert-dialog-title" >
          {t("Close multiple invoice payments")}
        </DialogTitle>

        <DialogContent>
          <DialogContentText>
            <input type="file" name="file" onChange={e => setFile(e.target.files[0])}/>
          </DialogContentText>
        </DialogContent>

        <DialogActions>
            <Button
                onClick={onClickDownloadTemplate}
                disabled={isLoading || file}
                variant="contained"
                color="secondary"
                autoFocus
            >
                {t('massiveUpload:Download template')}
            </Button>
            <Button
                key='ipmts_btn_b'
                disabled={isLoading || !file}
                onClick={onProcess}
                variant="contained"
                color="secondary"
                autoFocus
            >
                {t("Process")}
            </Button>
        </DialogActions>
      </Dialog>
    </>
  )
}


const InvoicePayment = (props) => {
    document.title = `Invoice Payments - BubbleBPM`
    const currentUser = useCurrentUser()
    const client = useApolloClient()

    const [ showClosePaymentsDialog, setShowClosePaymentsDialog ] = useState(false)

    let onCloseClosePaymentDialog = () => {
      setShowClosePaymentsDialog(false);
    }

    var tableCreatorCreator = tableCreatorCreatorFactoryCreator.getTableCreatorCreatorFromIdentifier(InvoicePaymentsTableCreator.getId(), null)
    tableCreatorCreator.setParentProps({...props, ...{path: props.match.url, currentUserData: currentUser}})
    const tableCreator = tableCreatorCreator.getTableCreator('Invoice Payments')
    tableCreator.setDisplayStrategy(new InvoicePaymentDisplayStrategy())
    tableCreator.setTableFooterItems([new CloseAPGLPaymentFooterCreator()]);
    tableCreator.setSelectedRowActionsStrategy(new InvoicePaymentSelectedRowActionsStrategy())
    tableCreator.setRightButtonDisplay(new PopUpComponentCreator(ClosePaymentButton, InvoiceClosePaymentePopUp, {showData: data => mustShowPopUp(data)}, "iconButton"))

    const path = props.match.url;

    return (
        <>
            <InvoicePaymentCloseAPGLDialog open={showClosePaymentsDialog} onClose={onCloseClosePaymentDialog}/>
            <BPMTable  
                TableCreator={tableCreator}
                path={path}
            />
        </>
    )
}

export default InvoicePayment
